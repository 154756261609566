import { initHeader } from "./header";
import { initTimeline } from "./timeline";
import { initNavigation } from "./navigation";
import { initTable } from "./table";
import { initLenis } from "./lenis";
import { initScrollEffects } from "./scrollEffects";
import { initHovers } from "./hovers";
import { gsap } from "gsap";

initHeader()




document.addEventListener("DOMContentLoaded",function(){
   


    
    initTimeline()
    initNavigation()
    initTable()
    initLenis()
    initScrollEffects()
    initHovers()
});
    


