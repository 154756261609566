import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


export function initHeader(){ 

    const video = document.getElementById('loader');
    const loaderHistory = document.getElementById('loader-history');
    const triggerNav = document.querySelector('.js-triggerNav');
    const navigation = document.querySelector('.js-navigation');
   
    const titleLoader = document.querySelector('.js-titleLoader');
    const buttonGroup = document.querySelector('.js-buttonGroup');

    const minDelay = 0.2; // 100ms
    const maxDelay = 0.5; // 500ms

     // Maak een shuffle functie om elementen in willekeurige volgorde te zetten
    function shuffle(array) {
        return array.sort(() => Math.random() - 0.5);
    }

  
    let videoWidth = "24vw";
    let videoHeight = "16vw";
    let videoTop = "68%";
  

    if(screen.width < 1281){
        videoWidth = '32vw';
        videoHeight = '20vw';
    }
    if(screen.width < 20){
        videoWidth = '48vw';
        videoHeight = '30vw';
        videoTop = '62%';
    }
    if(screen.width < 668){
        videoWidth = '60vw';
        videoHeight = '37vw';
        videoTop = '61%';
    }



    if(loaderHistory){
        window.onbeforeunload = () => {
            window.scrollTo(0, 0);
        }

        const images = document.querySelectorAll('.o-loader__image');
        const shuffledImages = shuffle([...images]);
        let tl = gsap.timeline({ });
        tl.to('.o-loader__title', {
            ease: 'power3.out',
            opacity: 1,
            duration: 1,
            delay: 0.5,
        });
        tl.to(shuffledImages, {
            ease: 'power3.out',
            stagger:gsap.utils.random(minDelay, maxDelay),
            duration: 1, // Duur van de animatie
            opacity: 1, // Beweeg de elementen 100px naar rechts
            delay: "-0.8",
        });
        triggerNavHeader();
    }
    
    if(video){
        window.onbeforeunload = () => {
            window.scrollTo(0, 0);
        }
        gsap.set(video, {
            scale: 1,
            translateX: '-50%',
            translateY: '-50%',
        });
        const images = document.querySelectorAll('.o-loader__image');
        // Definieer de minimale en maximale vertraging
        const minDelay = 0.2; // 100ms
        const maxDelay = 0.5; // 500ms

       
        
        const shuffledImages = shuffle([...images]);
        let firstTime = true;
       // video.addEventListener('play', () => {
            intro(firstTime, shuffledImages);
            firstTime = false;
       // });





        video.addEventListener('mouseenter', () => {   
            if(video.classList.contains('-hover') && !video.classList.contains('-fullscreen')){
                gsap.to(video, {
                    ease: 'power3.out',
                    scale: 1.2,
                    duration: 0.6,
                    overwrite: true,
                });
            }
        });

        video.addEventListener('mouseleave', () => {   
            if(video.classList.contains('-hover') && !video.classList.contains('-fullscreen')){
                gsap.to(video, {
                    ease: 'power3.in',
                    scale: 1,
                    duration: 0.4,
                    overwrite: true,
                });
            }
        });

        video.addEventListener('click', () => {  
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            if(video.classList.contains('-hover') && !video.classList.contains('-fullscreen')){  
                videoOpen();
            } else if(video.classList.contains('-hover') && video.classList.contains('-fullscreen')){
                videoClose();
            }
        }); 

        ScrollTrigger.create({
            trigger: video,
            start: "center top",
            markers: false,
            onEnter: () => {
                videoClose();
               
            }
        });
      
        triggerNavHeader();

    }


    function triggerNavHeader(){
        gsap.set(navigation, {
            translateY: "-100%",
            opacity: 0.4,
            overwrite: true,
        });
        ScrollTrigger.create({
            trigger: triggerNav,
            start: "top top",
            end: "+=100px",
            markers: false,
            onEnter: () => {
                gsap.set(navigation, {
                    translateY: "-100%",
                    opacity: 0.4,
                    overwrite: true,
                });
            },
            onEnterBack: () => {
                gsap.to(navigation, {
                    translateY: "-100%",
                    opacity: 0.4,
                    overwrite: true,
                    duration: 0.8,
                    ease: "power3.inOut",
                });
            },
            onLeave: () => {
                gsap.to(navigation, {
                    translateY: "0",
                    opacity: 1,
                    overwrite: true,
                    duration: 0.8,
                    ease: "power3.inOut",
                });
            }
        });
    }


    function intro(firstTime, shuffledImages){
        if(firstTime){
            let tl = gsap.timeline({ });
            tl.to('.o-loader__title', {
                ease: 'power3.out',
                opacity: 1,
                duration: 1,
                delay: 0.2,
            });
           
            tl.to(video, {
                ease: 'power3.inOut',
                width: videoWidth,
                height: videoHeight,
                top: videoTop,
                duration: 2,
                delay: "0.6",
            });
            tl.to('.o-loader__buttonGroup', {
                ease: 'power3.inOut',
                opacity: 1,
                duration: 1,
            });
           
            tl.to(shuffledImages, {
                ease: 'power3.out',
                stagger:gsap.utils.random(minDelay, maxDelay),
                duration: 1, // Duur van de animatie
                opacity: 1, // Beweeg de elementen 100px naar rechts
                delay: "-0.8",
            });
            setTimeout(() => {
                video.classList.add('-hover');
            }, 3600);
            
        }
    }


    function videoOpen(){
        video.currentTime = 0;
        video.classList.add('-fullscreen');
        gsap.to(video, {
            ease: 'power3.inOut',
            width: '100%',
            height: '100%',
            scale: 1,
            top: '50%',
            duration: 1,
        });    
        gsap.to(titleLoader, {
            ease: 'power3.inOut',
            top: "8%",
            scale: 0.4,
            duration: 1,
        });   
        gsap.to(buttonGroup, {
            ease: 'power3.inOut',
            opacity: 0,
            duration: 1,
        });   
        
        setTimeout(() => {
            video.muted = false;
        }, 800);
    }

    function videoClose(){
        video.muted = true;
        gsap.to(video, {
            ease: 'power3.inOut',
            width: videoWidth,
            height: videoHeight,
            top: videoTop,
            scale: 1,
            duration: 1,
            overwrite: true,
        });
        gsap.to(titleLoader, {
            ease: 'power3.inOut',
            top: "50%",
            scale: 1,
            duration: 1,
            overwrite: true,
        });   
        gsap.to(buttonGroup, {
            ease: 'power3.inOut',
            opacity: 1,
            duration: 1,
            overwrite: true,
        }); 
        setTimeout(() => {
            video.classList.remove('-fullscreen');
        }, 1000);
    }
}