import { gsap } from "gsap";



export function initNavigation(){ 
    const navigation = document.querySelector('.m-navigation');
    const toggle = document.querySelector('.js-toggleNavigation');
    const hamburger = document.querySelector('.js-hamburger');
    const navigationItems = document.querySelector('.m-navigation__items');

    toggle.addEventListener('click', function() {
        hamburger.classList.toggle('-active');
        if(hamburger.classList.contains('-active')){
            gsap.to(navigationItems, {
                duration: 0.5,
                height: 'calc(100dvh - 16vw)',
                ease: 'power3.out',
            });
        } else {
            gsap.to(navigationItems, {
                duration: 0.5,
                height: '0',
                ease: 'power3.out',
            });
        }
        
    });
}