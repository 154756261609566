import { gsap } from "gsap";

export function initHovers() {
    document.addEventListener('mouseover', (e) => {
        if (e.target.closest('.js-imageHover')) {
            const imageContainer = e.target.closest('.js-imageHover').querySelector('.m-post__image');
            gsap.to(imageContainer, 1, {padding: "1vw", ease: "power3.out", overwrite: true})
            //gsap.to(image, 1, {scale: "1.04", ease: "power4.out", overwrite: true})
        }
        if (e.target.closest('.js-btn')) {
            const btn = e.target.closest('.js-btn')
            const svgPath = btn.querySelectorAll('svg path')
            gsap.to(btn, 1, {backgroundColor: "transparent", color: "#030923", ease: "power3.out", overwrite: true})
            gsap.to(svgPath, 0.5, {fill: "#030923", ease: "power3.out", overwrite: true})
        }
    });

    document.addEventListener('mouseout', (e) => {
        if (e.target.closest('.js-imageHover')) {
            const imageContainer = e.target.closest('.js-imageHover').querySelector('.m-post__image');
            gsap.to(imageContainer, 0.5, {padding: "0.5vw", ease: "power3.out", overwrite: true})
        }
        if (e.target.closest('.js-btn')) {
            const btn = e.target.closest('.js-btn')
            const svgPath = btn.querySelectorAll('svg path')
            gsap.to(btn, 0.5, {backgroundColor: "#030923", color: "#E8E8E8", ease: "power3.out", overwrite: true})
            gsap.to(svgPath, 0.5, {fill: "#F2D680", ease: "power3.out", overwrite: true})
        }
    });
}