import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function initTimeline(){


    const toggleMenu = document.querySelector('.js-toggleMenu');
    const menuItems = document.querySelector('.js-historyNavigationItems');
    const allMenuItems = document.querySelectorAll('.m-historyNavigation__item');


    const sections = document.querySelectorAll('.js-timelineSection');
    const playVideos = document.querySelectorAll('.js-playVideo');
    const select = document.querySelector('.js-historyNavigationSelect');


    if(select){
        select.addEventListener('change', function() {
            const year = select.value;
            const navigationItem = document.getElementById(`nav_section_${year}`);
            window.location.hash = `#scroll-section_${year}`;
           
        });
    }

    if(toggleMenu){
        toggleMenu.addEventListener('click', function() {
            menuItems.classList.toggle('is-open');
            toggleMenu.classList.toggle('-active');
        });
    }

    

    if(allMenuItems){
        allMenuItems.forEach(item => {
            item.addEventListener('click', function() {
                menuItems.classList.remove('is-open');
                toggleMenu.classList.remove('-active');
            });
        });
    }

    gsap.utils.toArray(playVideos).forEach(element => { 
        const video = element.querySelector('video');
        const playIcon = element.querySelector('.js-playVideoIcon');
        element.addEventListener('click', function() {
            video.controls = true;
            setTimeout(() => {
                video.play();
            }, 100);
            playIcon.remove();
            
        });
    });

    gsap.utils.toArray(sections).forEach(element => { 

        const title = element.querySelector(".js-timelineTitle");
        const titleOverlay = element.querySelector(".js-timelineTitle span");
        const year = element.id.split('_')[1];
        const navigationItem = document.getElementById(`nav_section_${year}`);
        const navigationItems = document.querySelectorAll('.m-historyNavigation__item');


        ScrollTrigger.create({

            trigger: title,
            endTrigger: element,
            start: "center center",
            end: "bottom top",
            pin: true,
            pinSpacing:false,
            markers: false,
            onEnter: () => {
                gsap.to(titleOverlay, {
                    ease: "power3.out",
                    duration: 1.4,
                    opacity: "0",
                    overwrite: true,
                });
                navigationItems.forEach(item => {
                    item.classList.remove('is-active');
                });
                navigationItem.classList.add('is-active');
                select.value = year;
                if(navigationItem.classList.contains('is-head')) {
                    navigationItems.forEach(item => {
                        item.classList.remove('is-head-active');
                    });
                    navigationItem.classList.add('is-head-active');

                }
            },
            onLeave: () => {
                //console.log("leave");
            },
            onEnterBack: () => {
                navigationItems.forEach(item => {
                    item.classList.remove('is-active');
                });
                navigationItem.classList.add('is-active');
                select.value = year;
                if(navigationItem.classList.contains('is-head')) {
                    navigationItems.forEach(item => {
                        item.classList.remove('is-head-active');
                    });
                    navigationItem.classList.add('is-head-active');

                }
                
            },
            onLeaveBack: () => {
                gsap.to(titleOverlay, {
                    ease: "power3.out",
                    duration: 1.4,
                    opacity: "1",
                    overwrite: true,
                });
            }
        });

        
    });



    
}