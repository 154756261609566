import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function initScrollEffects() {


  
   

    const parallaxElements = document.querySelectorAll(".js-parallax");


    gsap.utils.toArray(parallaxElements).forEach(element => { 

        let parent = element.parentElement;
        let random = Math.floor(Math.random() * 71) - 70;
        gsap.to(element, {
            yPercent: random,
            ease: "none",
            scrollTrigger: {
                trigger: parent,
                // start: "top bottom", // the default values
                // end: "bottom top",
                scrub: true,
            }, 
        });
      

    });


    const scrollInElements = document.querySelectorAll(".js-scrollIn");
    gsap.utils.toArray(scrollInElements).forEach(element => { 
        gsap.set(element, { opacity: 0, translateY: "80px" });
        ScrollTrigger.create({
            trigger: element,
            start: "top bottom-=15%",
            markers: false,
            onEnter: function() {
                gsap.to(element, { opacity: 1, translateY: 0, duration: 1.5, ease: "power3.out" });
            }
        });
    });

    const scrollInElementsStagger = document.querySelectorAll(".js-scrollInStagger");
    gsap.utils.toArray(scrollInElementsStagger).forEach(element => { 
        let children = element.querySelectorAll('.js-scrollChilds');
        let childrenOpacity = element.querySelectorAll('.js-scrollChildsOpacity');
        gsap.set(children, { opacity: 0, translateY: "80px" });
        gsap.set(childrenOpacity, { opacity: 0 });
        ScrollTrigger.create({
            trigger: element,
            start: "top bottom-=15%",
            markers: false,
            onEnter: function() {
                gsap.to(children, { stagger: 0.18, opacity: 1, translateY: 0, duration: 1.5, ease: "power3.out" });
                gsap.to(childrenOpacity, { stagger: 0.18, opacity: 1, duration: 1.5, ease: "power3.out" });
            }
        });
    });


    const winnersImages = document.querySelectorAll(".js-galleryWinners");
    gsap.utils.toArray(winnersImages).forEach(element => { 
        let row_odd = element.querySelectorAll('.js-galleryWinnersOdd');
        let row_even = element.querySelectorAll('.js-galleryWinnersEven');

        gsap.set(row_even, {  translateX: "-39%" }); 8.7
        gsap.set(row_odd, {  translateX: "14.5%" }); 30.3
        let tl = gsap.timeline({ defaults: { ease: "none" }, paused: true });

        tl.fromTo(row_even, {  translateX: "-29%" }, {  translateX: "1.3%" }, 0);
        tl.fromTo(row_odd, {  translateX: "4.5%" }, {  translateX: "-20.3%" }, 0);
        ScrollTrigger.create({
            trigger: element,
            start: "top bottom",
            end: "bottom top",
            markers: false,
            onUpdate: function(self) {
                tl.progress(self.progress);
            }
        });
    });


    const statements = document.querySelectorAll(".js-statements");
    statements.forEach(element => { 
        const statementItems = element.querySelectorAll('.o-statements__item');
        const wordDelay = 200;   // 300ms tussen woorden bij toevoegen
        const removeDelay = 50; // 100ms tussen woorden bij weghalen
        const holdTime = 2000;   // 3s vasthouden wanneer regel compleet is

        
        async function animateLine(item) {
            const words = item.querySelectorAll('.-word');
            
            // Woorden één voor één goud maken
            for(let word of words) {
                word.classList.add('-gold');
                await new Promise(resolve => setTimeout(resolve, wordDelay));
            }
            
            // Wacht de holdTime
            await new Promise(resolve => setTimeout(resolve, holdTime));
            
            // Woorden één voor één terug naar normaal
            for(let word of words) {
                word.classList.remove('-gold');
                await new Promise(resolve => setTimeout(resolve, removeDelay));
            }
        }
        
        async function animateStatements() {
            
            
            while(true) {
                for(let item of statementItems) {
                    await animateLine(item);
                }
            }
        }

        // Start de animatie direct
        animateStatements();
    });

    

}